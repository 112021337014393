import $Utils from '../mixins/utils'

export default {
  mixins: [$Utils],
  props: {
    validationError: String
  },
  computed: {
    isAnswerValid () {
      return this.isNull(this.validationError) ||
             !this.isDefined(this.validationError)
    }
  }
}
