<template lang="html">
  <div>
    <input
      :value='formPageId'
      type="hidden"
      name="form_page[form_page_id]"
    >
    <input
      :value='initialValue'
      type="hidden"
      name="form_page[value]"
    >
    <div class='validation-error' v-if='!isAnswerValid'>
      {{ this.validationError }}
    </div>
    <div v-else>
      <div class="question-description">
        We've calculated your BMI (Body Mass Index) as {{initialValue}} from your weight and height. This will be included in your response to this questionnaire. 
        </div>
      <div class="question-description">
        For more information on BMI, please refer to the <a class="link-style" href="https://www.nhs.uk/live-well/healthy-weight/bmi-calculator/">NHS BMI calculator</a>.
      </div>
      <input
        type="submit"
        value="Next"
        data-disable-with="Next"
        class="ze-button"
      >
    </div>
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,
  props: {
    initialValue: String,
    formPageId: String,
  }
}
</script>
