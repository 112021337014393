export default {
  methods: {
    notEmptyObject(someObject){
      return someObject && Object.keys(someObject).length > 0
    },
    isDefined(someObject) {
      return someObject !== undefined
    },
    isNull(someObject) {
      return someObject === null
    },
    isValuePresent(someObject) {
      return this.isDefined(someObject) && !this.isNull(someObject)
    },
    isInteger(str) {
      return !!str.match(/^-?\d+$/)
    },
    isDecimal(str) {
      return !!str.match(/^-?\d+(?:\.\d{1,1})?$/)
    },
    isNonNegativeDecimal(str) {
      return !!str.match(/^\d{1,3}(?:\.\d{1,1})?$/)
    },
    isNonNegativeInteger(str) {
      return !!str.match(/^\d+$/)
    },
    notEmptyArray(someArray) {
      return someArray && someArray.length > 0
    },
    isEmptyArray(someArray) {
      return someArray && someArray.length == 0
    },
    notEmptyString(str) {
      return str && str.length > 0
    },
    dateToString(date) {
      if (!this.isNull(date) && this.isDefined(date)) {
        let yyyy = date.getFullYear()
        let mm = ('0'+(date.getMonth()+1)).slice(-2)
        let dd = ('0'+date.getDate()).slice(-2)
        return dd + '/' + mm + '/' + yyyy
      }
    },
    stringToDate(str) {
      if (!this.isNull(str) && this.isDefined(str)) {
        let dd = parseInt(str.substring(0,2));
        let mm = parseInt(str.substring(3,5));
        let yyyy = parseInt(str.substring(6,10));
        return new Date(yyyy, mm-1, dd);
      }
    },
    copyObject(object) {
      return Object.assign({}, object);
    }
  }
}
