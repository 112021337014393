<template lang="html">
  <div>
    <input
      :value='formPageId'
      type="hidden"
      name="form_page[form_page_id]"
    >
    <div class='validation-error' v-if='!isAnswerValid'>
      {{ this.validationError }}
    </div>
    <input
      type="text"
      name="form_page[value]"
      :class="['text-input', { 'invalid-input': !isAnswerValid }]"
      v-model='value'
      aria-label='title'
      :aria-required=isMandatory
      :required=isMandatory
      autocomplete
    >
    <input
      type="submit"
      value="Next"
      data-disable-with="Next"
      class="ze-button"
      :disabled='isSubmitButtonDisabled'
    >
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,
  props: {
    formPageId: String,
    initialValue: String,
    isMandatory: Boolean
  },
  data: function () {
    return {
      value: this.initialValue
    }
  },
  computed: {
    isSubmitButtonDisabled () {
      return this.isMandatory &&
             (!this.isDefined(this.value) ||
              !this.notEmptyString(this.value))
    }
  }
}
</script>
