import Vue from 'vue';
import VCalendar from 'v-calendar';

import DatePage from '../../components/form_builder/patient-ui/date-page';
import InfoPage from '../../components/form_builder/patient-ui/info-page';
import IntegerPage from '../../components/form_builder/patient-ui/integer-page';
import BmiPage from '../../components/form_builder/patient-ui/bmi-page';
import BodyMeasuresPage from '../../components/form_builder/patient-ui/body-measures-page';
import LongTextPage from '../../components/form_builder/patient-ui/long-text-page';
import MultiplePage from '../../components/form_builder/patient-ui/multiple-page';
import SinglePage from '../../components/form_builder/patient-ui/single-page';
import TextPage from '../../components/form_builder/patient-ui/text-page';
import WaitListPage from '../../components/form_builder/patient-ui/wait-list-page';

const VCalendarConfig = {
  componentPrefix: 'vc',
  screens: {
    desktop: '640px',
  },
};

Vue.use(VCalendar, VCalendarConfig);

Vue.component('fb-date-page', DatePage);
Vue.component('fb-info-page', InfoPage);
Vue.component('fb-integer-page', IntegerPage);
Vue.component('fb-bmi-page', BmiPage);
Vue.component('fb-body-measures-page', BodyMeasuresPage);
Vue.component('fb-long-text-page', LongTextPage);
Vue.component('fb-multiple-page', MultiplePage);
Vue.component('fb-single-page', SinglePage);
Vue.component('fb-text-page', TextPage);
Vue.component('fb-wait-list-page', WaitListPage);

document.addEventListener('DOMContentLoaded', () => {
  const $element = document.getElementById('fb-page');

  $element &&
    new Vue({
      el: $element,

      components: {
        DatePage,
        InfoPage,
        IntegerPage,
        BodyMeasuresPage,
        LongTextPage,
        MultiplePage,
        SinglePage,
        TextPage,
        WaitListPage,
      },
    });
});
