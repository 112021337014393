<template lang="html">
  <div>
    <input
      :value='formPageId'
      type="hidden"
      name="form_page[form_page_id]"
    >
    <div class='validation-error' v-if='!isAnswerValid'>
      {{ this.validationError }}
    </div>
    <input
      type="text"
      name="form_page[value]"
      :class="['integer-input', { 'invalid-input': !isAnswerValid }]"
      v-model='value'
      aria-label='title'
      aria-describedby=hint
      aria-required=true
      required=true
      autocomplete
    >
    <div id='hint' class='question-description right'>
      Please enter whole number between {{ this.minValue }} and {{ this.rangeMaxValue }}.
    </div>
    <input
      type="submit"
      value="Next"
      data-disable-with="Next"
      class="ze-button"
      :disabled='isSubmitButtonDisabled'
    >
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,
  props: {
    formPageId: String,
    initialValue: String,
    minValue: Number,
    maxValue: Number
  },
  data: function () {
    return {
      value: this.initialValue
    }
  },
  computed: {
    isSubmitButtonDisabled () {
      return !this.isDefined(this.value) ||
             !this.isInteger(this.value) ||
             parseInt(this.value) < this.minValue ||
             parseInt(this.value) > this.rangeMaxValue
    },
    rangeMaxValue () {
      return this.maxValue - 1;
    }
  }
}
</script>
