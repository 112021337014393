<template lang="html">
  <div>
    <input
      :value='formPageId'
      type="hidden"
      name="form_page[form_page_id]"
    >
    <input
        :value='unitSystem'
        type="hidden"
        name="form_page[value][units]"
    >
    <div v-if="unitSystem === 'imperial'">
      <div class='validation-error' v-if='!isAnswerValid'>
        {{ this.validationError }}
      </div>
      <div class="ent-form-row">
        <div class="ent-form-row__title">
          <label class="ent-form-label">{{ unitText.imperial.label }}</label>
          <button type="button" class="ent-button ent-button--white ent-button--switch-right" v-on:click="switchToMetric">
            <span>Switch to metric</span>
          </button>
        </div>
        <div class="ent-h-align">
          <div class="ent-h-align__spread">
            <div class="ent-text-field">
              <input
                  type="text"
                  :name="getInputName.imperial.primary"
                  :class="[{ 'invalid-input': !isAnswerValid }]"
                  v-model='imperialValuePrimary'
                  aria-label='title'
                  aria-describedby=hint
                  aria-required=true
                  required=true
                  autocomplete
              >
            </div>
          </div>
          <span class="ent-prefix-suffix" style="margin-right: 10px">{{ unitText.imperial.inputAfterTextPrimary }}</span>
          <div class="ent-h-align__spread">
            <div class="ent-text-field">
              <input
                  type="text"
                  :name="getInputName.imperial.secondary"
                  :class="[{ 'invalid-input': !isAnswerValid }]"
                  v-model='imperialValueSecondary'
                  aria-label='title'
                  aria-describedby=hint
                  aria-required=true
                  required=true
                  autocomplete
              >
            </div>
          </div>
          <span class="ent-prefix-suffix">{{ unitText.imperial.inputAfterTextSecondary }}</span>
        </div>
        <div class="input-description">
          {{ unitText.imperial.description }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class='validation-error' v-if='!isAnswerValid'>
        {{ this.validationError }}
      </div>
      <div class="ent-form-row">
        
        <div class="ent-form-row__title">
          <label class="ent-form-label">{{ unitText.metric.label }}</label>
          <input
              type="button"
              class="ent-button ent-button--white ent-button--switch-right"
              value="Switch to imperial"
              v-on:click="switchToImperial">
        </div>
        <div class="ent-h-align">
          <div class="ent-h-align__spread">
            <div class="ent-text-field">
              <input
                  type="text"
                  :name="getInputName.metric"
                  :class="[{ 'invalid-input': !isAnswerValid }]"
                  v-model='metricValue'
                  aria-label='title'
                  aria-describedby=hint
                  aria-required=true
                  required=true
                  autocomplete
              >
            </div>
          </div>
          <span class="ent-prefix-suffix">{{ unitText.metric.inputAfterText }}</span>
        </div>
        <div class = "input-description">
          {{ unitText.metric.description }}
        </div>
      </div>
    </div>
    <input
      type="submit"
      value="Next"
      data-disable-with="Next"
      class="ze-button"
      :disabled='isSubmitButtonDisabled'
    >
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,
  props: {
    formPageId: String,
    measureType: {
      type: String,
      default: 'wight'
    },
    initialMetric: String,
    initialImperialPrimary: String,
    initialImperialSecondary: String,
    initialUnitSystem: {
      type: String,
      default: 'metric'
    },
    maxHeight: Number,
    maxWeight: Number
  },
  data: function () {
    return {
      metricValue: this.initialMetric,
      imperialValuePrimary: this.initialImperialPrimary,
      imperialValueSecondary: this.initialImperialSecondary,
      unitSystem: this.initialUnitSystem
    }
  },
  computed: {
    isSubmitButtonDisabled () {
      switch (this.unitSystem) {
        case 'imperial':
          const isValidPrimary = parseInt(this.imperialValuePrimary) <= this.unitMaxValues.imperial.maxPrimary;
          const isValidSecondary = parseInt(this.imperialValueSecondary) <= this.unitMaxValues.imperial.maxSecondary;
          return !(this.isDefined(this.imperialValuePrimary) && this.isDefined(this.imperialValueSecondary) &&
              this.isNonNegativeInteger(this.imperialValuePrimary) && this.isNonNegativeInteger(this.imperialValueSecondary) &&
              isValidPrimary && isValidSecondary);
        case 'metric':
          const isValid = parseInt(this.metricValue) <= this.unitMaxValues.metric.maxValue;
          return !(this.isDefined(this.metricValue) && this.isNonNegativeDecimal(this.metricValue) && isValid);
        default:
          break;
      }
    },
    unitText () {
      switch (this.measureType) {
        case 'height':
          return {
            metric: {
              label: "Height (cm)",
              inputAfterText: "cm",
              description: "Add up to 1 decimal place, e g 190.2 or 176.6."
            },
            imperial: {
              label: "Height (ft, in)",
              inputAfterTextPrimary: "ft",
              inputAfterTextSecondary: "in",
              description: "Please enter to the nearest whole stone and nearest whole pound."
            },
          };
        case 'weight':
          return {
            metric: {
              label: "Weight (kg)",
              inputAfterText: "kg",
              description: "Add up to 1 decimal place, e g 90.2 or 76.6."
            },
            imperial: {
              label: "Weight (st, lb)",
              inputAfterTextPrimary: "st",
              inputAfterTextSecondary: "lb",
              description: "Please enter to the nearest whole stone and nearest whole pound."
            },
          };
        default:
          break;
      }
    },
    unitMaxValues () {
      switch (this.measureType) {
        case 'height':
          return {
            metric: {
              maxValue: this.maxHeight //cm
            },
            imperial: {
              maxPrimary: 8, //ft
              maxSecondary: 11 //inch
            },
          };
        case 'weight':
          return {
            metric: {
              maxValue: this.maxWeight //kg
            },
            imperial: {
              maxPrimary: 100, // st
              maxSecondary: 13 //lb
            },
          };
        default:
          break;
      }
    },
    getInputName() {
      switch (this.measureType) {
        case 'height':
          return {
            metric: "form_page[value][metric][cm]",
            imperial: {
              primary: "form_page[value][imperial][ft]", //ft
              secondary: "form_page[value][imperial][in]" //inch
            },
          };
        case 'weight':
          return {
            metric:  "form_page[value][metric][kg]", //kg
            imperial: {
              primary: "form_page[value][imperial][st]", // st
              secondary: "form_page[value][imperial][lb]" //lb
            },
          };
        default:
          break;
      }
    },
    
  },
  methods: {
    switchToMetric: function () {
      this.unitSystem = 'metric'
      this.imperialValuePrimary = ''
      this.imperialValueSecondary = ''
    },

    switchToImperial: function () {
      this.unitSystem = 'imperial'
      this.metricValue = ''
    }
  }

}
</script>
