<template lang="html">
  <div>
    <input :value='formPageId' type="hidden" name="form_page[form_page_id]">
    <input :value='strDate' type="hidden" name="form_page[value]">
    <div class='validation-error' v-if='!isAnswerValid'>
      {{ this.validationError }}
    </div>
    <vc-date-picker
      v-model='selectedDate'
      :columns="$screens({ default: 1, desktop: 1 })"
      is-expanded
      is-inline
      class='date-picker-container'
      @transition-end='handleTransition'
    ></vc-date-picker>

    <input type="submit" value="Next" data-disable-with="Next" class="ze-button" :disabled='isSubmitButtonDisabled'>
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,
  props: {
    formPageId: String,
    initialValue: String,
    isMandatory: Boolean
  },
  data: function () {
    return {
      selectedDate: this.stringToDate(this.initialValue)
    }
  },
  computed: {
    isSubmitButtonDisabled () {
      return this.isMandatory &&
             (!this.isDefined(this.selectedDate) ||
             this.isNull(this.selectedDate))
    },
    strDate () {
      return this.dateToString(this.selectedDate)
    }
  },
  mounted() {
    this.addListenerToTitle();
  },
  methods: {
    addListenerToTitle: function() {
      $('.vc-title').attr('tabindex', 0);
      $('.vc-title').keypress(function(event) {
        if (event.which == 13) {
          event.preventDefault();
          this.click();
        }
      });
    },
    handleTransition: function(e) {
      this.addListenerToTitle();
      $('.vc-title').focus();
    }
  }
}
</script>
