<template lang="html">
  <div>
    <input
      :value="formPageId"
      type="hidden"
      name="form_page[form_page_id]"
    />
    <div
      class="validation-error"
      v-if="!isAnswerValid"
    >
      {{ this.validationError }}
    </div>
    <fieldset>
      <legend class="sr-only">
        {{ this.fieldsetLegend }}
      </legend>
      <summary
        class="sr-only"
        v-if="this.fieldsetSummary.length > 0"
      >
        {{ this.fieldsetSummary }}
      </summary>
      <label
        class="question-check-list-item"
        v-for="option in optionsCollection"
        @click="isSubmitButtonDisabled = false"
        :for="option.value"
      >
        <input
          type="radio"
          :value="option.value"
          name="form_page[value]"
          :checked="option.value == initialValue"
          :id="option.value"
        />
        <span>{{ option.label }}</span>
      </label>
    </fieldset>
    <input
      type="submit"
      value="Next"
      data-disable-with="Next"
      class="ze-button"
      :disabled="isSubmitButtonDisabled"
    />
  </div>
</template>

<script>
import Base from './base.js';

export default {
  extends: Base,
  props: {
    fieldsetLegend: String,
    fieldsetSummary: String,
    formPageId: String,
    initialValue: String,
    optionsCollection: Array,
  },
  data: function () {
    return {
      isSubmitButtonDisabled: !this.isDefined(this.initialValue),
    };
  },
};
</script>
