<template lang="html">
  <div>
    <input
      :value='formPageId'
      type="hidden"
      name="form_page[form_page_id]"
    >
    <div class='validation-error' v-if='!isAnswerValid'>
      {{ this.validationError }}
    </div>
    <fieldset>
      <legend class='sr-only'>
        {{ this.fieldsetLegend }}
      </legend>
      <summary class='sr-only' v-if='this.fieldsetSummary.length>0'>
        {{ this.fieldsetSummary }}
      </summary>
      <label
        class="question-check-list-item"
        v-for="option in optionsCollection"
        :for='option.value'
      >
        <input
          type="checkbox"
          :value="option.value"
          name="form_page[value][]"
          v-model='option.checked'
          :id="option.value"
        >
        <span>{{ option.label }}</span>
      </label>
    </fieldset>
    <input
      type="submit"
      value="Next"
      data-disable-with="Next"
      class="ze-button"
      :disabled=isSubmitButtonDisabled
    >
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,
  props: {
    fieldsetLegend: String,
    fieldsetSummary: String,
    formPageId: String,
    initialCollection: Array,
    isMandatory: Boolean
  },
  data: function () {
    return {
      optionsCollection: this.initialCollection
    }
  },
  computed: {
    isSubmitButtonDisabled () {
      return this.isMandatory && !this.notEmptyArray(this.chosenOptions)
    },
    chosenOptions () {
      let chosen = [];
      this.optionsCollection.map(function(option, key) {
        if (option.checked) { chosen.push(option); }
      });
      return chosen;
    }
  }
}
</script>
