<template lang="html">
  <div>
    <div v-if="videoEnabled && videoId">
      <iframe
        id="ytplayer"
        class="youtube-video"
        frameborder="0"
        :src="videoSrc"
        style="aspect-ratio: 16 / 9;"
        type="text/html"
        width="100%">
      </iframe>

      <div v-if="showConfirmation">
        <fieldset>
          <label
            class="question-check-list-item"
            for='confirmation'
          >
            <input
              id="confirmation"
              v-model="confirmationChecked"
              name="confirmation"
              type="checkbox"
              :value="true"
            >
            <span>{{ confirmationTextDisplay }}</span>
          </label>
        </fieldset>
      </div>
    </div>

    <div>
      <input
        name="form_page[form_page_id]"
        type="hidden"
        :value='formPageId'
      >
      <div v-if='!isAnswerValid' class='validation-error'>
        {{ validationError }}
      </div>
      <input
        class="ze-button"
        data-disable-with="Next"
        :disabled="isButtonDisabled"
        type="submit"
        value="Next"
      >
    </div>
  </div>
</template>

<script>

import Base from './base.js'

export default {
  extends: Base,

  props: {
    confirmationText: {
      type: String,
      default: '',
    },
    formPageId: {
      type: String,
      required: true,
    },
    showConfirmation: {
      type: Boolean,
      default: false,
    },
    videoEnabled: {
      type: Boolean,
      default: false,
    },
    videoId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      confirmationChecked: false,
    }
  },

  computed: {
    confirmationTextDisplay() {
      return this.confirmationText.length > 0 ? this.confirmationText : 'I confirm that I have watched and understood the video above';
    },
    isButtonDisabled() {
      if (!this.videoEnabled || !this.videoId) return false;

      return (
        this.showConfirmation &&
        !this.confirmationChecked
      );
    },
    videoSrc() {
      return `https://www.youtube.com/embed/${this.videoId}?controls=0&autoplay=1&iv_load_policy=3&showinfo=0&rel=0`
    }
  }
}
</script>
